$colors-cardinal-red: #9f1d20;
$colors-gray-dark: #2d2b2a;
$colors-gray-medium: #9f9f9f;
$colors-silver: #d1d3d4;
$colors-gray-light: #f2f2f3;

$fonts-open-sans: 'Open Sans', sans-serif;
$fonts-oswald: 'Oswald', sans-serif;

$primary: $colors-cardinal-red;

$font-family-sans-serif: $fonts-open-sans;

$font-size-base: 0.9rem;
$font-size-lg: $font-size-base * 1;
$font-size-sm: $font-size-base * 0.8;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.8;
$h3-font-size: $font-size-base * 1.6;
$h4-font-size: $font-size-base * 1.4;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base * 1;

$grid-gutter-width: 18px;

$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

$table-cell-padding: 0.5rem 1rem;
$table-cell-padding-sm: 0.25rem 0.5rem;

$table-cell-vertical-align: center;

$card-spacer-y: 0.5rem !default;
$card-spacer-x: 1rem !default;

$alert-padding-x: 0.5rem;
$alert-padding-y: 0.25rem;

@import '~bootstrap/scss/bootstrap';

$rt-toast-width: auto;
$rt-toast-min-height: auto;
$rt-toast-max-height: auto;

$rt-color-default: $primary;
$rt-color-info: $info;
$rt-color-success: $success;
$rt-color-warning: $warning;
$rt-color-error: $danger;

@import '~react-toastify/scss/main';

a {
  cursor: pointer;
}

footer {
  background-color: $gray-100;
  border-top: 1px solid $gray-300;
  color: $gray-500;
}

h1 {
  font-weight: 700;
}

.btn {
  white-space: nowrap;
}

.cursor {
  cursor: pointer;
}

.card-header {
  font-weight: 700;
}

.navbar-brand {
  font-size: $font-size-base;
  & img {
    height: 25px;
  }
}

.nav-link {
  white-space: nowrap;
  &.disabled {
    text-decoration: line-through !important;
  }
}

.nav-tabs {
  .nav-link {
    color: $secondary;
    &.active {
      color: $primary;
    }
  }
}

.required {
  color: $red;
}

.select-all-large {
  &.is-invalid {
    & > div:first-of-type {
      border: 1px solid $red;
    }
  }
  & ~ .invalid-feedback {
    display: block;
  }
  & > div:first-of-type {
    & > div {
      padding: 0 2px;
    }
  }
}

.select-all-small {
  &.is-invalid {
    & > div:first-of-type {
      border: 1px solid $red;
    }
  }
  & ~ .invalid-feedback {
    display: block;
  }
  & > div:first-of-type {
    height: 35px;
    & > div {
      margin-top: -1px;
      padding: 0 2px;
    }
  }
}

.table {
  & tbody {
    & td {
      vertical-align: middle;
      white-space: nowrap;
      &.actions {
        padding: 0.5rem;
      }
    }
  }
  & thead {
    & th {
      cursor: pointer;
      vertical-align: middle;
      white-space: nowrap;
      &.actions {
        padding: 0.5rem;
        text-align: center;
      }
    }
  }
}

.table-sm {
  & tbody {
    & td {
      &.actions {
        padding: 0.25rem;
      }
    }
  }
  & thead {
    & th {
      &.actions {
        padding: 0.25rem;
      }
    }
  }
}

.#{$rt-namespace}__toast {
  font-size: $h5-font-size;
  padding: $spacer;
}

.#{$rt-namespace}__toast-container {
  &--top-right {
    right: 12px;
    top: 12px;
  }
  .#{$rt-namespace}__close-button {
    font-size: $h5-font-size;
    margin-left: 10px;
  }
}
